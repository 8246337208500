/* eslint-disable camelcase */
import axios from "axios";


const url = "https://api.nostech.co.tz/api/v1/";

axios.defaults.baseURL = url;

// eslint-disable-next-line import/prefer-default-export
export const signinApi = async (data1, endpoint) => {
  try {
    const response = await axios({
      method: "post",
      url: url + endpoint,
      data: JSON.stringify({
        ...data1,
      }),
    });
    return response;
  } catch (error) {
    return error;
  }
};



export const generalPostApi = async (data1, endpoint) => {
  try {
    const response = await axios({
      method: "post",
      url: url + endpoint,
      data: JSON.stringify({
        ...data1,
      }),
    });
    return response;
  } catch (error) {
    return error;
  }
};

//api to send multipart
export const multimediaApi = async (data1,endpoint) => {

  try {
    const data = await axios({
      method: "post",
      url: url+endpoint,
      data: data1,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return data
  } catch (e) {

  }

};

export const createSubscriberApi = async (details) => {
  try {
    const response = await axios({
      method: "post",
      data: JSON.stringify({
        api: 120,
        code: 111,
        data: {
          ...details,
        },
      }),
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const pullSubscribersApi = async (endpoint) => {
  try {
    const response = await axios({
      method: "get",
      url: url + endpoint,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const pullSubscriberByIdApi = async (operator_id, subscriber_id) => {
  try {
    const response = await axios({
      method: "post",
      data: JSON.stringify({
        api: 120,
        code: 113,
        data: {
          operator_id,
          account_id: subscriber_id,
        },
      }),
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const pullSubscriberUsersApi = async (operator_id, subscriber_id) => {
  try {
    const response = await axios({
      method: "post",
      data: JSON.stringify({
        api: 100,
        code: 112,
        data: {
          operator_id,
          account_id: subscriber_id,
        },
      }),
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const pullStatsApi = async (operator_id) => {
  try {
    const response = await axios({
      method: "post",
      data: JSON.stringify({
        api: 120,
        code: 114,
        data: {
          operator_id,
        },
      }),
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const disableAccountApi = async (details,endpoint) => {
  try {
    const response = await axios({
      method: "post",
      url:url + endpoint,
      data: JSON.stringify(details),
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const changeAccountPasswordApi = async (operator_id, account_id, password) => {
  try {
    const response = await axios({
      method: "post",
      data: JSON.stringify({
        api: 120,
        code: 116,
        data: {
          operator_id,
          account_id,
          password,
        },
      }),
    });
    return response;
  } catch (error) {
    return error;
  }
};


export const disableSingleBackgroundApi = async (endpoint) => {
  try {
    const response = await axios({
      method: "post",
      url: url + endpoint,
    });
    return response;
  } catch (error) {
    return error;
  }
};


export const getStatsApi = async () => {
  try {
    const response = await axios({
      method: "get",
      url: url + "nostech/stats",
    });
    return response;
  } catch (error) {
    return error;
  }
};



export const toggleAccountSocialStatusApi = async (endpoint) => {
  try {
    const response = await axios({
      method: "post",
      url: url + endpoint,
    });
    return response;
  } catch (error) {
    return error;
  }
};


export const toggleAccountSocialIconStatusApi = async (endpoint) => {
  try {
    const response = await axios({
      method: "post",
      url: url + endpoint,
    });
    return response;
  } catch (error) {
    return error;
  }
};