import React from 'react'
import {styled} from '@mui/material/styles';
import {Typography} from "@mui/material";
import PropTypes from 'prop-types';


const Container = styled("div")({
  borderRadius: '10px',
  padding:'5px',
  minWidth:'22px',
  // maxWidth:'100px',
  height:'24px',
  display:'flex',
  alignItems:'center'


});


const colorGenerator = (color)=>{
  switch (color) {
    case "published":
      return 'rgba(76, 175, 80, 0.3)';
      break;
    case "expired":
      return 'rgba(255, 0, 0, 0.3)';
      break;
    default:
      return 'rgba(255, 215, 0, 0.3)'

  }
}


const colorGenerator2 = (color)=>{
  switch (color) {
    case "published":
      return 'rgba(76, 175, 80, 1)';
      break;
    case "expired":
      return 'rgba(255, 0, 0, 1)';
      break;
    default:
      return 'rgba(255, 215, 0, 1)'

  }
}

const AccountStatus =({msg,variant})=>{
  return(
    <Container style={{
      backgroundColor:colorGenerator(variant),
      color:colorGenerator2(variant),
    }} >
      <Typography align="center" variant="subtitle2" >
        {msg}
      </Typography>
    </Container>
  )

}


AccountStatus.propTypes = {
  msg: PropTypes.string.isRequired,
  variant:PropTypes.string.isRequired,
};

export default AccountStatus;


