/* eslint-disable camelcase */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useState, useEffect} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ClearIcon from '@mui/icons-material/Clear';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// react-router-dom components
import {useNavigate, useParams} from "react-router-dom";

// Notistack
import {useSnackbar} from "notistack";

// Overview page components
import Header from "layouts/userProfile/components/Header";
import PlatformSettings from "layouts/userProfile/components/PlatformSettings";
import UserForm from "../../components/UserForm";
// chart
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import {Pie} from "react-chartjs-2";

// icons
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

import PdfPlaceHolder from "../../assets/images/PDF_file_icon.svg.png"

// API
import {pullSubscriberByIdApi, pullSubscriberUsersApi} from "services";
import SubscriberUserForm from "components/SubscriberUserForm";
import {fDate} from "utilities";

import PieChart from "examples/Charts/PieChart";
import {disableAccountApi} from "services";
import LoadingComponent from "components/LoadingComponent";
import {changeAccountPasswordApi} from "services";
import SocialController from "../../components/SocialController";
import {
  disableSingleBackgroundApi,
  generalPostApi,
  multimediaApi,
  pullSubscribersApi,
  toggleAccountSocialStatusApi
} from "../../services";
import Backdrop from "@mui/material/Backdrop";
import {CircularProgress} from "@mui/material";
import AccountStatus from "../../components/AccountStatus";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

ChartJS.register(ArcElement, Tooltip, Legend);

function UserProfile() {
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {id} = useParams();

  //  states
  const [processLoading, setProcessLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openViewUser, setOpenViewUser] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [user, setUser] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [openEditUser, setOpenEditUser] = useState(false)
  const [subscriberUsers, setSubscriberUsers] = useState([]);
  const [details, setDetails] = useState({});
  const [isAccActive, setIsAccActive] = useState(true);
  const [editAccStatusOpen, setEditAccStatusOpen] = useState(false)
  const [password, setPassword] = useState(null);
  const [socialList, setSocialList] = useState([])
  const [openEditSocial, setOpenEditSocial] = useState(false)
  const [openEditPhoto, setOpenEditPhoto] = useState(false)
  const [selectedEditPhoto, setSelectedEditPhoto] = useState()
  const [editPhotoType, setEditPhotoType] = useState()
  const [selectedSocial, setSelectedSocial] = useState()
  const [socialDetails, setSocialDetails] = useState({})
  const [fonts, setFonts] = useState(
    [
      {value: "h5"},
      {value: "subtitle1"},
      {value: "subtitle2"},
      {value: "h4"},
    ]
  )
  const [accStatus, setAccStatus] = useState(
    [
      {value: "expired"},
      {value: "published"},
      {value: "not_published"}
    ]
  )
  const [attachmentSocials, setAttachmentSocials] = useState([
    "aec12b8938834146b9dd62bed62784d5",
    "44aea964d18a4ef8b8cbedaabd211c73"
  ])
  const [background, setBackground] = useState()
  const [singleBgCheck, setSingleBgCheck] = useState(false)
  const [dep, setDep] = useState(1)

  // chart
  const data1 = {
    labels: ["not scanned", "scanned"],
    datasets: [
      {
        label: "# of Tickets",
        data: [user?.scanned_count, user?.unscanned_count],
        backgroundColor: ["rgba(255, 99, 132", "rgb(68, 221, 49)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(75, 192, 192, 1)", "rgba(255, 159, 64, 1)"],
        borderWidth: 1,
      },
    ],
  };

  // functions

  const userViewHandler = (restOfDetails, username, phone_number) => {
    setDetails({...restOfDetails, username, phone_number});
    setOpenViewUser(true);
  };
  const handleCloseViewUser = () => {
    setOpenViewUser(false);
  };

  const handleChangePasswordOpen = () => {
    setOpenChangePassword(true);
  };
  const handleChangePasswordClose = () => {
    setOpenChangePassword(false);
  };
  const handleChange = (e) => {
    setUserDetails({...userDetails, [e.target.name]: e.target.value});
  };
  const handleChangeBioFontSize = (e) => {
    setUserDetails({...userDetails, bio_fonts: e.target.value});
  }

  const handleChangeComplete = (color, selected) => {
    console.log(color)
    console.log("selected", selected)
    setBackground({background: color.hex});
    setUserDetails({...userDetails, [selected]: color.hex});

  };
  // API
  const pullSubscriberByIdHandler = async (endpoint) => {
    setLoading(true);
    try {
      const {
        data: {code, account},
      } = await pullSubscribersApi(endpoint);
      if (code === 200) {
        setUser(account);
        setSingleBgCheck(account.single_background)
        setLoading(false);
        setIsAccActive(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const pullSubscriberSocialsHandler = async (endpoint) => {
    setLoading(true);
    try {
      const {
        data: {code, socials},
      } = await pullSubscribersApi(endpoint);
      if (code === 200) {
        setSocialList(socials);
        setLoading(false);
        setIsAccActive(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const changeAccountStatusHandler = async (e) => {
    e.preventDefault()
    setLoading(true);
    let info = {
      account_id: id,
      status: details.status
    }
    try {
      const {
        data: {code, msg, account},
      } = await disableAccountApi(info, `nostech/account_edit_status`);
      if (code === 200) {
        setLoading(false);
        setUser(account);
        enqueueSnackbar("Account Status updated succesfully!", {variant: "success"});
        setEditAccStatusOpen(false)
      } else {
        setLoading(false);
        enqueueSnackbar("Something  went wrong", {variant: "error"});
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const disableSingleBackgroundHandler = async (endpoint) => {
    setLoading(true);
    try {
      const {
        data: {code, account},
      } = await disableSingleBackgroundApi(endpoint);
      if (code === 200) {
        setUser(account);
        setSingleBgCheck(account.single_background)
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const editSocialHandler = (social) => {
    console.log("i", social)
    setSelectedSocial(social)
    setOpenEditSocial(true)
    setSocialDetails(
      {
        id: social.id,
        label: social.label,
        value: social.value,
        font_color: social.font_color,
        font_size: social.font_size
      })
  }

  const editPhotoHandler = (type) => {

    setEditPhotoType(type)
    setOpenEditPhoto(true)
  }

  const editSocialApiHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    var data1 = new FormData();
    data1.append('id', socialDetails.id);
    data1.append('value', socialDetails.value);
    data1.append('label', socialDetails.label);
    data1.append('font_size', socialDetails.font_size);
    data1.append('font_color', socialDetails.font_color);
    for (let i = 0; i < imageSource2.length; i++) {
      data1.append(`image`, imageSource2[i])
    }
    if( attachmentSocials.includes(selectedSocial?.social.social_id)){
      for (let i = 0; i < imageSource2two.length; i++) {
        data1.append(`attachment`, imageSource2two[i])
      }
    }


    try {
      const {
        data: {code,},
      } = await multimediaApi(data1, "nostech/account_social_edit_image");
      if (code === 200) {
        setLoading(false);
        setImageSource([])
        setImageSource2([])

        //logic to update social list after editing one social
        let editedSocial  = socialList.find((item)=>item.id ===socialDetails.id)
        editedSocial = { ...editedSocial,...socialDetails}
        let filteredList = [...socialList.filter((item)=>item.id !==socialDetails.id)]
        let itemIndex = socialList.findIndex((item)=>item.id === socialDetails.id)
        filteredList.splice(itemIndex,0,editedSocial)
        setSocialList(filteredList)
        //end of logic

        enqueueSnackbar("Value updated succesfully!", {variant: "success"});
      } else {
        setLoading(false);
        enqueueSnackbar("Something  went wrong", {variant: "error"});
      }
    } catch (error) {
      setLoading(false);
    }
  }


  const editPhotosApiHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (imageSource2.length === 0) {
      setLoading(false)
      enqueueSnackbar("Please select photo", {variant: "error"});

    } else {


      var data1 = new FormData();
      data1.append('account_id', id);
      for (let i = 0; i < imageSource2.length; i++) {
        data1.append(`image`, imageSource2[i])
      }

      try {
        const {
          data: {code,},
        } = await multimediaApi(data1, `nostech/upload/${editPhotoType}`);
        if (code === 200) {
          setLoading(false);
          enqueueSnackbar("Photo updated succesfully!", {variant: "success"});
          setOpenEditPhoto(false)
          setDep(dep + 1)
          setImageSource2([])
          setImageSource([])

        } else {
          setLoading(false);
          enqueueSnackbar("Something  went wrong", {variant: "error"});
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }


  const disableSocialHandler = async (id) => {
    setLoading(true);
    try {
      const {
        data: {code, social},
      } = await toggleAccountSocialStatusApi(`nostech/account_social_edit_status/${id}`);
      if (code === 200) {

        let newList = [...socialList]

        let objIndex = newList.findIndex((obj => obj.id == id));

        newList[objIndex].status = social.status

        setSocialList([...newList])
        setLoading(false);
        enqueueSnackbar("Account social Status updated succesfully!", {variant: "success"});
        // setDep(dep + 1)
      } else {
        setLoading(false);
        enqueueSnackbar("Something  went wrong", {variant: "error"});
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const toggleIconStatusHandler = async (id) => {
    setLoading(true);
    try {
      const {
        data: {code, social},
      } = await toggleAccountSocialStatusApi(`nostech/account_social_edit_icon_status/${id}`);
      if (code === 200) {

        let newList = [...socialList]

        let objIndex = newList.findIndex((obj => obj.id == id));

        newList[objIndex].icon_status = social.icon_status

        setSocialList([...newList])
        setLoading(false);
        enqueueSnackbar("Account social Icon Status updated succesfully!", {variant: "success"});
        // setDep(dep + 1)
      } else {
        setLoading(false);
        enqueueSnackbar("Something  went wrong", {variant: "error"});
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const editUserApiHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const {
        data: {code,account},
      } = await generalPostApi(userDetails, "nostech/account_edit");
      if (code === 200) {
        setUser(account)
        setLoading(false);
        enqueueSnackbar("Value updated succesfully!", {variant: "success"});
      } else {
        setLoading(false);
        enqueueSnackbar("Something  went wrong", {variant: "error"});
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const handleEditUser = (user) => {
    setOpenEditUser(true)
    setUserDetails(user)
  }


  const handleChangeSocial = (e) => {
    setSocialDetails({
      ...socialDetails,
      [e.target.name]: e.target.value
    });
  };
  const handleChangeFontSize = (e) => {
    setSocialDetails({
      ...socialDetails,
      font_size: e.target.value
    });
  }

  const handleChangeAccStatus = (e) => {
    setDetails({
      ...details,
      status: e.target.value
    });
  }

  //image functionalities
  const [imageSource, setImageSource] = useState([])
  const [imageSource2, setImageSource2] = useState([])

  const [imageSourcetwo, setImageSourcetwo] = useState([])
  const [imageSource2two, setImageSource2two] = useState([])


  var fileObj = []
  var fileArray = [];
  let fileObjtwo = []
  let fileArraytwo = [];

  const [is_File, set_is_file] = useState(false)


  const imageHandler = async (e) => {
    setImageSource2(e.target.files)
    set_is_file(false)

    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
    }
    setImageSource({file: fileArray})
  }
  const imageDelete = (key) => {
    set_is_file(false)

    const imageArray = [...imageSource.file]
    imageArray.splice(key, 1)
    setImageSource({file: imageArray})
    setImageSource2([])
  }

  const imageHandlertwo = async (e) => {
    setImageSource2two(e.target.files)
    if(e.target.files[0].name.split('.').pop() === "pdf"){
      set_is_file(true)
    }else{
      set_is_file(false)
    }

    fileObjtwo.push(e.target.files)
    for (let i = 0; i < fileObjtwo[0].length; i++) {
      fileArraytwo.push(URL.createObjectURL(fileObjtwo[0][i]))
    }
    setImageSourcetwo({file: fileArraytwo})
  }

  const imageDeletetwo = (key) => {
    set_is_file(false)

    const imageArray = [...imageSourcetwo.file]
    imageArray.splice(key, 1)
    setImageSourcetwo({file: imageArray})
    setImageSource2two([])
  }


  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };


  const singleBackgroundHandler = (event, value) => {
    if (value) {
      editPhotoHandler("background")

    } else {
      disableSingleBackgroundHandler(`nostech/disable_background/${id}`)
    }
  }

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(`http://nostech.co.tz/user/${user?.username}`);
    enqueueSnackbar("link Copied!", {variant: "success"});

  }

  useEffect(() => {
    if (validateAdmin(userData)) {
      pullSubscriberByIdHandler(`nostech/account/${id}`);
      pullSubscriberSocialsHandler(`nostech/account_social/${id}`);
      // pullSubscriberUsersHandler();
    } else {
      navigate("/authentication/sign-in");
    }
  }, [dep]);


  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox mb={2}/>
      <Header cover={user?.cover_photo}
              profile={user?.profile_photo}
              name={user?.name} subscriptionEnd={user?.username}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>

              <PlatformSettings
                disableAccountHandler={() => console.log("s")}
                isActive={isAccActive}
              />
              <Stack direction="row">
                < MDTypography variant="button" color="text" fontWeight="bold">
                  Expiration date:{user?.expires}
                </MDTypography>
              </Stack>
              <Stack sx={{pr: 3}}>
                <AccountStatus variant={user?.status} msg={user?.status}/>
              </Stack>


              <Button onClick={() => setEditAccStatusOpen(true)} sx={{mt: 2}}
                      style={{background: "green", color: "white"}}>
                Edit account status
              </Button>
              <Stack sx={{p: 2}}>
                <Stack direction="row">
                  < MDTypography variant="button" color="text" fontWeight="bold">
                    color1:
                  </MDTypography>
                  < MDTypography variant="button" color="text" fontWeight="light">
                    {user.color_one}
                  </MDTypography>
                </Stack>
                <Stack sx={{mt: 2}} direction="row">
                  < MDTypography variant="button" color="text" fontWeight="bold">
                    color2:
                  </MDTypography>
                  < MDTypography variant="button" color="text" fontWeight="light">
                    {user.color_two}
                  </MDTypography>
                </Stack>
                <Stack sx={{mt: 2}} direction="row">
                  < MDTypography variant="button" color="text" fontWeight="bold">
                    color3:
                  </MDTypography>
                  < MDTypography variant="button" color="text" fontWeight="light">
                    {user.color_three}
                  </MDTypography>
                </Stack>

                <Stack sx={{mt: 2}} justifyContent="space-between" alignItems="center" direction="row">
                  <MDTypography variant="button" color="text" fontWeight="bold">
                    Enable single background
                  </MDTypography>
                  <IconButton onClick={() => editPhotoHandler("background")}>
                    <EditIcon/>
                  </IconButton>
                  <Switch checked={singleBgCheck} onChange={singleBackgroundHandler}/>

                </Stack>
                <Stack spacing={2} sx={{mt: 2}} justifyContent="space-between" alignItems="center" direction="row">
                  < MDTypography variant="button" color="text" fontWeight="bold">
                    Edit Profile photo
                  </MDTypography>
                  <Button onClick={() => editPhotoHandler("profile")}
                          style={{background: "green", color: "white"}}>
                    Edit
                  </Button>
                </Stack>

                <Stack spacing={2} sx={{mt: 2}} justifyContent="space-between" alignItems="center" direction="row">
                  < MDTypography variant="button" color="text" fontWeight="bold">
                    Edit cover photo
                  </MDTypography>
                  <Button onClick={() => editPhotoHandler("cover")}
                          style={{background: "green", color: "white"}}>
                    Edit
                  </Button>
                </Stack>

                <Button onClick={() => handleEditUser(user)} sx={{mt: 2}}
                        style={{background: "green", color: "white"}}>
                  Edit details
                </Button>
              </Stack>

            </Grid>
            <Grid sx={{display: 'flex', justifyContent: 'space-between'}} item xs={12} md={6} xl={4}>
              <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
              <Stack>
                <MDTypography sx={{pt: 2}} variant="h6" fontWeight="medium" textTransform="capitalize">
                  Socials
                </MDTypography>
                <Stack spacing={2}>
                  {socialList.length < 0 ? <div>No socials yet</div> : socialList.map((item, i) => {
                    return (
                      <SocialController
                        key={i}
                        disableSocialHandler={() => disableSocialHandler(item.id)}
                        editHandler={() => editSocialHandler(item)}
                        toggleIconStatusHandler={() => toggleIconStatusHandler(item.id)}
                        isActive={item.status}
                        icon={item.icon}
                        icon_status={item.icon_status}
                        name={item.social.name}
                        social={item}
                      />
                    )
                  })}
                </Stack>

                <Button
                  onClick={copyLinkHandler}
                  sx={{mt: 3}}
                  style={{background: "green", color: "white"}}
                  startIcon={<ContentCopyIcon/>}
                >
                  Copy Link
                </Button>
              </Stack>

              <Divider orientation="vertical" sx={{mx: 0}}/>
            </Grid>
            <Grid item xs={12} xl={4}>
              <Stack>
                <MDTypography sx={{pt: 2}} variant="h6" fontWeight="medium" textTransform="capitalize">
                  Preview
                </MDTypography>
                <Stack>
                  <div style={{background: user.color_one, height: "400px"}}>
                    <div style={{background: user.color_one, height: '30%'}}>
                      <div style={{margin: 'auto', background: "white", width: '30%'}}>
                        color 1
                      </div>
                    </div>
                    <div style={{background: user.color_two, height: '60%', margin: "20px"}}>
                      <div style={{margin: 'auto', background: "white", width: '30%'}}>
                        color 2
                      </div>

                      <div style={{margin: '5px', height: '30px', background: user.color_three}}>
                        instagram
                        <div style={{margin: 'auto', background: "white", width: '30%'}}>
                          color 3
                        </div>
                      </div>

                    </div>
                  </div>

                </Stack>
              </Stack>


            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Dialog
        open={openViewUser}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleCloseViewUser}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle/>
        <DialogContent>
          <SubscriberUserForm
            details={details}
            view
            // handleChange={handleChange}
            // submitHandler={addUserHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewUser}>close</Button>
        </DialogActions>
      </Dialog>

      {/* edit social dialog */}

      <Dialog
        open={openEditSocial}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => setOpenEditSocial(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Change {selectedSocial?.social.name} value</DialogTitle>
        <DialogContent>
          <form onSubmit={editSocialApiHandler}>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox>
                {attachmentSocials.includes(selectedSocial?.social.social_id) ?
                  null : <MDBox mb={2}>
                    <MDInput
                      type="string"
                      name="value"
                      value={socialDetails?.value}
                      InputLabelProps={{shrink: socialDetails?.value}}
                      onChange={handleChangeSocial}
                      label="Value"
                      fullWidth
                      required
                    />
                  </MDBox>
                }

                <MDBox mb={2}>

                  <MDInput
                    type="string"
                    name="label"
                    value={socialDetails?.label}
                    InputLabelProps={{shrink: socialDetails?.label}}
                    onChange={handleChangeSocial}
                    label="Label"
                    fullWidth
                    required
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Font size</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="font_size"
                      label="Font size"
                      sx={{height: '40px'}}
                      value={socialDetails?.font_size}
                      onChange={handleChangeFontSize}
                      fullWidth
                      required
                    >
                      {fonts.map((item) => {
                        return (
                          <MenuItem value={item.value}>{item.value}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="string"
                    name="font_color"
                    value={socialDetails?.font_color}
                    InputLabelProps={{shrink: socialDetails?.font_color}}
                    onChange={handleChangeSocial}
                    label="Font color"
                    fullWidth
                    required
                  />
                </MDBox>

                <MDBox mb={2}>
                  <div>
                    <div>
                      <div>
                        <input onChange={imageHandler} accept="image/*" style={{display: 'none'}}
                               id="icon-button-file" type="file"/>
                        <label htmlFor="icon-button-file">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <CropOriginalIcon style={{color: 'red'}}/>
                          </IconButton>
                        </label>
                      </div>
                      <MDTypography variant="button" color="text" fontWeight="light">
                        Attach Icon
                      </MDTypography>
                    </div>

                    <Grid container>
                      {(imageSource.file || []).map((url, i) => (
                        <Grid key={i} item md={6} sm={6} xs={6}>
                          <div>
                            <ClearIcon onClick={() => imageDelete(i)}/>
                            <img src={url} alt="..." width='97%' height='auto'/>
                          </div>
                        </Grid>
                      ))}
                    </Grid>

                  </div>
                </MDBox>

                {attachmentSocials.includes(selectedSocial?.social.social_id) ?

                  <MDBox mb={2}>
                    <div>
                      <div>
                        <div>
                          <input onChange={imageHandlertwo} accept="*" style={{display: 'none'}}
                                 id="icon-button-file-2" type="file"/>
                          <label htmlFor="icon-button-file-2">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                              <CropOriginalIcon style={{color: 'red'}}/>
                            </IconButton>
                          </label>
                        </div>
                        <MDTypography variant="button" color="text" fontWeight="light">
                          Atttach pdf
                        </MDTypography>
                      </div>

                      <Grid container>
                        {(imageSourcetwo.file || []).map((url, i) => (
                          <Grid key={i} item md={6} sm={6} xs={6}>
                            <div>
                              <ClearIcon onClick={() => imageDeletetwo(i)}/>
                              <img src={ is_File? PdfPlaceHolder:url} alt="..." width='97%' height='auto'/>
                            </div>
                          </Grid>
                        ))}
                      </Grid>

                    </div>
                  </MDBox> : null}


                <Button style={{color: "white"}} type="submit" variant="contained">
                  Save
                </Button>
              </MDBox>
            </MDBox>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditSocial(false)}>close</Button>
        </DialogActions>
      </Dialog>

      {/*end edit social*/}

      <Dialog
        open={openEditUser}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        onClose={() => setOpenEditUser(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Edit user Dialog</DialogTitle>
        <DialogContent>
          <UserForm
            loading={processLoading}
            details={userDetails}
            handleChange={handleChange}
            handleChangeFontSize={handleChangeBioFontSize}
            submitHandler={editUserApiHandler}
            background={background}
            handleChangeComplete={handleChangeComplete}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditUser(false)}>close</Button>
        </DialogActions>
      </Dialog>


      {/* edit photo dialog */}

      <Dialog
        open={openEditPhoto}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => setOpenEditPhoto(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Change {editPhotoType} photo</DialogTitle>
        <DialogContent>
          <form onSubmit={editPhotosApiHandler}>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox>
                <MDBox mb={2}>
                  <div>
                    <div>
                      <div>
                        <input onChange={imageHandler} accept="image/*" style={{display: 'none'}}
                               id="icon-button-file" type="file"/>
                        <label htmlFor="icon-button-file">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <CropOriginalIcon style={{color: 'red'}}/>
                          </IconButton>
                        </label>
                      </div>
                      <MDTypography variant="button" color="text" fontWeight="light">
                        Attach Photo
                      </MDTypography>
                    </div>

                    <Grid container>
                      {(imageSource.file || []).map((url, i) => (
                        <Grid key={i} item md={6} sm={6} xs={6}>
                          <div>
                            <ClearIcon onClick={() => imageDelete(i)}/>
                            <img src={url} alt="..." width='97%' height='auto'/>
                          </div>
                        </Grid>
                      ))}
                    </Grid>


                    {/*<RHFUploadSingleFile name="cover" accept="image/*" maxSize={3145728} onDrop={handleDrop} />*/}
                  </div>


                </MDBox>
                <Button style={{color: "white"}} type="submit" variant="contained">
                  Save
                </Button>
              </MDBox>
            </MDBox>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditPhoto(false)}>close</Button>
        </DialogActions>
      </Dialog>

      {/*end photo social*/}


      {/* edit background dialog */}


      {/*end edit background*/}

      {/*edit account status*/}
      <Dialog
        open={editAccStatusOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => setEditAccStatusOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle> Edit account status</DialogTitle>
        <DialogContent>
          <form onSubmit={changeAccountStatusHandler}>

            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Account status s</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Account status"
                  sx={{height: '40px'}}
                  value={user?.status}
                  onChange={handleChangeAccStatus}
                  fullWidth
                  required
                >
                  {accStatus.map((item) => {
                    return (
                      <MenuItem value={item.value}>{item.value}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </MDBox>
            <Button style={{color: "white"}} type="submit" variant="contained">
              Save
            </Button>
          </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditAccStatusOpen(false)}>close</Button>
        </DialogActions>
      </Dialog>
      {/*end edit account status*/}


      <div>
        <Backdrop
          sx={{color: '#fff', zIndex: 3333}}
          open={loading}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
      </div>


      <Footer/>
    </DashboardLayout>
  );
}

export default UserProfile;
