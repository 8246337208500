/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react imports
import React, {useEffect, useState} from "react";

// @mui material components
import {Grid, Card} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import LoadingComponent from "components/LoadingComponent";
// react-router-dom components
import {useNavigate} from "react-router-dom";

// icons
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// data
import userTableData from "components/UserTableData";

// API
import {pullStatsApi} from "services";
import {getStatsApi} from "../../services";
import HorizontalBarChart from "../../examples/Charts/BarCharts/HorizontalBarChart";

function Dashboard() {
  const {sales, tasks} = reportsLineChartData;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const {rows, columns, loadingTable} = userTableData();

  const pullStatsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: {code, ...rest},
      } = await getStatsApi();
      if (code === 200) {
        setLoading(false);
        setStats(rest);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    validateAdmin(userData) ? pullStatsHandler() : navigate("/authentication/sign-in");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="person_add"
                title="Total accounts"
                count={stats?.statistics?.total}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="person_add"
                title="Published accounts"
                count={stats?.statistics?.published}
                // percentage={{
                //   color: "success",
                //   amount: "",
                //   label: "Just updated",
                // }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="person_add"
                title="Expired accounts"
                count={stats?.statistics?.expired_count}
                // percentage={{
                //   color: "success",
                //   amount: "",
                //   label: "Just updated",
                // }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="person_add"
                title="Not published "
                count={stats?.statistics?.not_published}
                // percentage={{
                //   color: "success",
                //   amount: "",
                //   label: "Just updated",
                // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                 {/*<HorizontalBarChart*/}
                 {/*  title={"test"}*/}
                 {/*  description={"sdfsdf"}*/}
                 {/*  icon={"app_user"}*/}
                 {/*  height={200}*/}
                 {/*  chart={reportsBarChartData}*/}

                 {/*/>*/}
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer/>
    </DashboardLayout>
  );
}

export default Dashboard;
