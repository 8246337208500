/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Button, IconButton, Avatar, CircularProgress } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Backdrop from '@mui/material/Backdrop'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// mui table
import MUIDataTable from "mui-datatables";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Notistack
import { useSnackbar } from "notistack";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";


import { createSubscriberApi, pullSubscribersApi } from "services";
import UserForm from "components/UserForm";
import MDInput from "../../components/MDInput";

// data
import userTableData from "components/UserTableData";

// icons
import PreviewIcon from "@mui/icons-material/Preview";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LoadingComponent from "components/LoadingComponent";
import {generalPostApi} from "../../services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Author = ({ account_name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <Avatar style={{ background: stringToColor(account_name) }} alt="A" src="f" sx={{ mr: 2 }}>
      {account_name.charAt(0)}
    </Avatar>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {account_name}
      </MDTypography>
    </MDBox>
  </MDBox>
);

function Users() {
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { rows, columns, loadingTable,dataSearchHandler } = userTableData();

  //  states
  const [usersList,setUsersList]=useState(rows??[])
  const [processLoading, setProcessLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setusers] = useState([]);
  const [details, setDetails] = useState({ operator_id: userData.operator_id });
  const [open, setOpen] = React.useState(false);
  const [background, setBackground] = useState()

  const [dep, setDep] = useState(1);
  // functions
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleChangeBioFontSize = (e) => {
    setDetails({...details, bio_fonts: e.target.value});
  }
  const handleChangeComplete = (color,selected) => {
    console.log(color)
    console.log("selected",selected)
    setBackground({background: color.hex});
    setDetails({...details, [selected]: color.hex});

  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetForm = () => {
    const obj = details;
    Object.keys(obj).forEach((key) => {
      obj[key] = "";
    });
    obj.operator_id = userData.operator_id;
    setDetails({ ...obj });
  };

  // API calls
  const addUserHandler = async (e) => {
    e.preventDefault();
    setProcessLoading(true);
    try {
      const {
        data: { code, msg,account },
      } = await generalPostApi(details,"nostech/account");
      if (code === 200) {
        enqueueSnackbar("Account created succesfully!", { variant: "success" });
        setProcessLoading(false);
        resetForm();
        setOpen(false);
        setDep(dep + 1);
      } else {
        enqueueSnackbar(msg, { variant: "error" });
        setProcessLoading(false);
      }
    } catch (error) {
      setProcessLoading(false);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  const[stats,setStats]=useState(0)
  const pullSubscribersHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, accounts,user_count },
      } = await pullSubscribersApi("nostech/account");
      if (code === 200) {
        setusers(accounts);
        setStats(user_count)
        setLoading(false);
        resetForm();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };



  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  const searchHandler=(query)=>{
    dataSearchHandler(query)

  }

  useEffect(() => {
    validateAdmin(userData) ? pullSubscribersHandler() : null;
  }, [dep]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <MDTypography variant="h6" color="white">
                      User Table
                    </MDTypography>
                  </div>
                  <div>
                    <Button
                      style={{ background: "green", color: "white" }}
                      onClick={handleClickOpen}
                    >
                      create user
                    </Button>
                  </div>
                </div>
              </MDBox>
              <MDBox pt={3}>

                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    canSearch={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                    searchHandler={searchHandler}

                  />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: 3333 }}
          open={loadingTable || processLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Add user Dialog</DialogTitle>
        <DialogContent>
          <UserForm
            loading={processLoading}
            details={details}
            handleChange={handleChange}
            submitHandler={addUserHandler}
            handleChangeFontSize={handleChangeBioFontSize}
            background={background}
            handleChangeComplete={handleChangeComplete}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default Users;
