import React, {useState} from 'react'
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar"
import InstagramIcon from "@mui/icons-material/Instagram";

import MDTypography from "./MDTypography";
import Switch from "@mui/material/Switch/Switch";
import { toggleAccountSocialStatusApi} from "../services";
import {useSnackbar} from "notistack";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import EditIcon from '@mui/icons-material/Edit';


const SocialController = ({icon_status,toggleIconStatusHandler, disableSocialHandler,icon, name, isActive,editHandler}) => {
  console.log("isActive",isActive)
  const {enqueueSnackbar} = useSnackbar();

  const [isAccActive, setIsAccActive] = useState(isActive);
  const [loading, setLoading] = useState(false);



  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row">

      <Avatar src={icon} >
      </Avatar>
      <MDTypography variant="button" color="text" fontWeight="light">
        {name}
      </MDTypography>
      <div>
        <IconButton onClick={editHandler} >
          <EditIcon/>
        </IconButton>
        <Switch checked={isActive==="active"?true:false} onChange={disableSocialHandler}/>
        <Switch checked={icon_status} onChange={toggleIconStatusHandler}/>
      </div>
    </Stack>

  )

}

export default SocialController;

