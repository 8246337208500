import React, {useState} from "react";
import {SketchPicker, BlockPicker, SwatchesPicker} from 'react-color'

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import {Button, Grid} from "@mui/material";
import Radio from '@mui/material/Radio';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function UserForm({loading, handleChangeFontSize, background, handleChangeComplete, details, handleChange, submitHandler}) {

  const [selectedValue, setSelectedValue] = React.useState('a');


  const [fonts, setFonts] = useState(
    [
      {value: "h5"},
      {value: "subtitle1"},
      {value: "subtitle2"},
      {value: "h4"},
    ]
  )
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };


  return (
    <form onSubmit={submitHandler}>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              value={details?.full_name}
              type="string"
              name="full_name"
              required
              InputLabelProps={{shrink: details?.full_name}}
              onChange={handleChange}
              label="Full name"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              value={details?.phone_number}
              type="number"
              name="phone_number"
              required
              InputLabelProps={{shrink: details?.phone_number}}
              onChange={handleChange}
              label="Phone number"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              value={details?.name}
              InputLabelProps={{shrink: details?.name}}
              type="string"
              name="name"
              required
              onChange={handleChange}
              label="Name"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
                value={details?.name_color}
                InputLabelProps={{shrink: details?.name_color}}
                type="string"
                name="name_color"
                // required
                onChange={handleChange}
                label="Name color"
                fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              value={details?.email}
              type="email"
              required
              InputLabelProps={{shrink: details?.email}}
              name="email"
              onChange={handleChange}
              label="Email"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="string"
              name="username"
              required
              InputLabelProps={{shrink: details?.username}}
              value={details?.username}
              onChange={handleChange}
              label="Username"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="number"
              required
              name="Phone"
              InputLabelProps={{shrink: details?.phone_number}}
              value={details?.phone_number}
              onChange={handleChange}
              label="Phone number"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="string"
              // required
              name="bio"
              InputLabelProps={{shrink: details?.bio}}
              value={details?.bio}
              onChange={handleChange}
              label="Bio"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
                type="string"
                // required
                name="bio_color"
                InputLabelProps={{shrink: details?.bio_color}}
                value={details?.bio_color}
                onChange={handleChange}
                label="Bio color"
                fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Font size</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="font_size"
                label="Font size"
                sx={{height: '40px'}}
                value={details?.bio_fonts}
                onChange={handleChangeFontSize}
                fullWidth
                required
              >
                {fonts.map((item) => {
                  return (
                    <MenuItem value={item.value}>{item.value}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="date"
              required
              name="expires"
              InputLabelProps={{shrink: details?.expires}}
              value={details?.expires}
              onChange={handleChange}
              label="Subscription end"
              fullWidth
            />
          </MDBox>
          <Grid spacing={3} container>
            <Grid item sx={12} sm={6} md={6}>

              <MDBox mb={2}>
                <MDInput
                  type="string"
                  required
                  name="color_one"
                  InputLabelProps={{shrink: details?.color_one}}
                  value={details?.color_one}
                  onChange={handleChange}
                  label="Color one"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      <Radio
                        checked={selectedValue === 'color_one'}
                        onChange={handleChangeRadio}
                        value="color_one"
                        name="radio-buttons"
                        inputProps={{'aria-label': 'A'}}
                      />
                    </InputAdornment>,
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="string"
                  required
                  name="color_two"
                  InputLabelProps={{shrink: details?.color_two}}
                  value={details?.color_two}
                  onChange={handleChange}
                  label="Color two"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      <Radio
                        checked={selectedValue === 'color_two'}
                        onChange={handleChangeRadio}
                        value="color_two"
                        name="radio-buttons"
                        inputProps={{'aria-label': 'A'}}
                      />
                    </InputAdornment>,
                  }}
                />

              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="string"
                  required
                  name="color_three"
                  value={details?.color_three}
                  InputLabelProps={{shrink: details?.color_three}}
                  onChange={handleChange}
                  label="Color three"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      <Radio
                        checked={selectedValue === 'color_three'}
                        onChange={handleChangeRadio}
                        value="color_three"
                        name="radio-buttons"
                        inputProps={{'aria-label': 'A'}}
                      />
                    </InputAdornment>,
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item sx={12} sm={6} md={6}>
              <SwatchesPicker
                color={background}
                onChangeComplete={(color) => handleChangeComplete(color, selectedValue)}
              />
            </Grid>
          </Grid>

          <Button style={{color: 'white'}} variant="contained" type="submit">
            Save
          </Button>


        </MDBox>
      </MDBox>
    </form>
  );
}

export default UserForm;
UserForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};
