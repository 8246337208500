import React from "react";

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { LoadingButton } from "@mui/lab";

function SubscriberUserForm({ loading, view, details, handleChange, submitHandler }) {
  return (
    <form onSubmit={submitHandler}>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              value={details?.full_name}
              type="stirng"
              disabled={view}
              name="full_name"
              onChange={handleChange}
              label="Name"
              fullWidth
              required
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              value={details?.user_name}
              type="stirng"
              disabled={view}
              name="username"
              onChange={handleChange}
              label="username"
              required
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              value={details?.email}
              type="email"
              disabled={view}
              name="email"
              required
              onChange={handleChange}
              label="Email"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="string"
              name="phone_number"
              disabled={view}
              required
              value={details?.phone_number}
              onChange={handleChange}
              label="Phone"
              fullWidth
            />
          </MDBox>
          {view ? null : (
            <MDBox mb={2}>
              <MDInput
                type="string"
                name="password"
                required
                disabled={view}
                value={details?.password}
                onChange={handleChange}
                label="Password"
                fullWidth
              />
            </MDBox>
          )}

          <MDBox mt={4} mb={1}>
            {submitHandler ? (
              <LoadingButton
                load={loading}
                type="submit"
                variant="contained"
                style={{
                  color: "white",
                  background: "linear-gradient(195deg, #42424a, #191919)",
                }}
              >
                create user
              </LoadingButton>
            ) : null}
          </MDBox>
        </MDBox>
      </MDBox>
    </form>
  );
}

SubscriberUserForm.defaultProps = {
  handleChange: null,
  submitHandler: null,
  view: false,
  loading: false,
};

SubscriberUserForm.propTypes = {
  submitHandler: PropTypes.func,
  handleChange: PropTypes.func,
  view: PropTypes.bool,
  loading: PropTypes.bool,
  details: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SubscriberUserForm;
