import { useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// API
import { signinApi } from "services";

// Notistack
import { useSnackbar } from "notistack";

function Basic() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  //  states

  const [processLoading, setProcessLoading] = useState(false);
  const [details, setDetails] = useState({});

  // functions

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  // API calls
  const signinHandler = async (e) => {
    e.preventDefault();
    if (details.email === undefined || details.email === "" || details.email === null) {
      enqueueSnackbar("Please fill all the fields", { variant: "error" });
    } else {
      try {
        setProcessLoading(true);
        const {
          data: { code, operator, message },
        } = await signinApi(
          { email: details.email, password: details.password },
          "auth/admin/signin"
        );
        if (code === 200) {
          enqueueSnackbar("Verified!");
          setProcessLoading(false);
          sessionStorage.setItem("userData", JSON.stringify(operator));
          navigate("/dashboard");
          //  localStorage.setItem("userDetails", JSON.stringify(data.data.user_details));
        } else {
          enqueueSnackbar(message, { variant: "error" });
          setProcessLoading(false);
          console.log("oops");
        }
      } catch (error) {
        setProcessLoading(false);
        console.log(error);
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <form onSubmit={signinHandler}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  name="email"
                  required
                  onChange={handleChange}
                  label="Email"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  name="password"
                  required
                  onChange={handleChange}
                  label="Password"
                  fullWidth
                />
              </MDBox>

              <MDBox mt={4} mb={1}>
                <LoadingButton
                  type="submit"
                  style={{ color: "white" }}
                  loading={processLoading}
                  // onClick={signinHandler}
                  variant="contained"
                >
                  signin
                </LoadingButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
