/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, {useState, useEffect} from "react";
import {Avatar} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// api
import {pullSubscribersApi} from "services";

// utilities
import {stringToColor, fDate} from "../utilities";

export default function UserTableData() {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loadingTable, setLoading] = useState(false);
  const [users, setusers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const pullSubscribersHandler = async () => {
    setLoading(true);
    try {
      const {
        data: {code, accounts},
      } = await pullSubscribersApi("nostech/account");
      if (code === 200) {
        setusers(accounts);
        setFilteredUsers(accounts);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const dataSearchHandler = (query)=>{
    if (query) {
      return  setFilteredUsers(users.filter((_user) => _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1))
    }
    return  setFilteredUsers(users)
  }


  useEffect(() => {
    pullSubscribersHandler();
  }, []);

  const Author = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Avatar style={{background: stringToColor(name)}} alt="A" src="f" sx={{mr: 2}}>
        {name.charAt(0)}
      </Avatar>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      {Header: "Name", accessor: "name", width: "25%", align: "left"},
      {Header: "username", accessor: "username", align: "left"},
      {Header: "Email", accessor: "email", align: "left"},
      {Header: "Phone", accessor: "phone_number", align: "center"},
      {Header: "Status", accessor: "status", align: "center"},
      {Header: "Action", accessor: "action", align: "center"},
    ],
    rows: filteredUsers.map((item) => {
      return {
        name: <Author name={item.name}/>,
        username: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.username}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="active" color="success" variant="gradient" size="sm"/>
          </MDBox>
        ),
        email: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.email}
          </MDTypography>
        ),
        phone_number: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {item.phone_number}
          </MDTypography>
        ),

        action: (
          <MDTypography
            component="a"
            href={`/userProfile/${item.account_id}`}
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            View
          </MDTypography>
        ),
      };
    }),
    loadingTable,
    dataSearchHandler
  };
}
